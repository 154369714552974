<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <Bank :value="bank_id" @autocompleteChange="bankChange" :class="bank_id ? '' : 'req-star'" color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="№ рахунку" v-model="account"
                          hide-details
                          :disabled="!bank_id"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="account ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Найменування для друку" v-model="print_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="print_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" v-if="is_service_service">
            <v-select :items="services"
                      filled label="Послуга"
                      hide-details
                      :rules="is_building_service ? [v => !!v || 'Це поле є обов’язковим'] : []"
                      :class="is_building_service ? service_id ? '' : 'req-star' : ''"
                      v-model="service_id"
                      color="grey"
                      class="mr-2"
            />
          </v-col>
          <v-col cols="12" v-if="is_building_service">
            <AddressElementSelect v-model="building_id"
                                  label="Будинок(-ки)" filled req
                                  select_type="building"
                                  :disabled="true"
            />
          </v-col>
          <v-col cols="6" md="7">
            <v-select :items="bankAccountTypesSelect" filled label="Вид рахунка"
                      hide-details
                      v-model="account_type" :disabled="!account"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="account_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="6" md="5">
            <v-switch
                v-model="main"
                inset color="success"
                hide-details
                :label="main ? 'Основний рахунок' : 'Не основний рахунок'"
            ></v-switch>
          </v-col>
          <v-col cols="12">
            <v-switch
                v-model="disable"
                inset color="success"
                hide-details
                :label="disable ? 'Рахунок не активний' : 'Рахунок активний'"
            ></v-switch>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" class="button-accept" :loading="getModalLoading">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {CREATE_BANK_ACCOUNT, REMOVE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT} from "@/store/actions/bank";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {bankAccountTypesSelect} from '@/utils/icons'

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'bank_account_modal_delete'

export default {
  name: "HWP_Modal_BankAccount",
  mixins: [ModalComponentMixin],
  components: {
    Bank: () => import('@/components/autocomplite/Bank'),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      bankAccountTypesSelect,
      bank_id: this.item.bank_id,
      account: this.item.account || '',
      account_type: this.item.account_type || '',
      print_name: this.item.print_name || '',
      main: this.item.main || false,
      service_id: this.item.service_id || null,
      building_id: this.item.building_id || null,
      disable: this.item.disable || false,
      is_organization_service: this.item.is_organization_service || false,
      is_service_service: this.item.is_service_service || false,
      is_building_service: this.item.is_building_service || false
    }
  },
  methods: {
    bankChange(payload) {
      this.bank_id = payload.value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.bank_id = this.item.bank_id
      this.account = this.item.account
      this.print_name = this.item.print_name
      this.account_type = this.item.account_type
      this.main = this.item.main
      this.service_id = this.item.service_id
      this.building_id = this.item.building_id
      this.disable = this.item.disabled
      this.is_service_service = this.item.is_service_service
      this.is_organization_service = this.item.is_organization_service
      this.is_building_service = this.item.is_building_service

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення банківського рахунку ${this.account}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Банк, № рахунку, Тип рахунку - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      if (this.is_building_service && !this.building_id) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Будинок має бути заповнений',
          color: 'error lighten-1'
        })
        return
      }

      if (this.is_building_service && !this.is_service_service && !this.service_id) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга має бути заповнена',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        bank_id: this.bank_id,
        account: this.account,
        print_name: this.print_name,
        account_type: this.account_type,
        main: this.main,
        service_id: this.service_id,
        building_id: this.building_id,
        disable: this.disable
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_BANK_ACCOUNT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_BANK_ACCOUNT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.bank_id = payload.bank_id
              this.account = payload.account
              this.account_type = payload.account_type
              this.print_name = payload.print_name
              this.main = payload.main
              this.service_id = payload.service_id
              this.building_id = payload.building_id
              this.disable = payload.disable
              this.is_service_service = payload.is_service_service
              this.is_organization_service = payload.is_organization_service
              this.is_building_service = payload.is_building_service
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_BANK_ACCOUNT, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
    }),
  }
}
</script>
